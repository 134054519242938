html {
  padding: 0;
  height: 100%;
}

body {
  margin: 0;
  display: flex;
  justify-content: center;
  font-family: "NotoSans", "Jua", "LeeSeoyun";
  /* font-family: "Noto Sans KR", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding: 0;
  height: 100%;
  background-color: #ffffff;
  
}

.app {
 /* position: fixed; */
 top: 0px;
 left: 0px;
 width: 100%;
 height: 100%;
}

h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

ul,
li {
  list-style: none;
}
ul {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-inline-start: 0 !important;
}
a:link {
  color : blue;
}
a:visited {
  color : blue;
}
a:hover {
  color : #35d2bb;
}