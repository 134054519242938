@font-face {
    font-family: "NotoSans";

    src: url("../fonts/NotoSansKR-Regular.otf") format('opentype'),
        url("../fonts/NotoSansKR-Regular.otf") format('woff'),
        url("../fonts/NotoSansKR-Regular.otf") format('woff2'),
        url("../fonts/NotoSansKR-Regular.otf") format('truetype');
  
    font-weight: 400;
    font-style: normal;
  }
@font-face {
    font-family: "NotoSans";

    src: url("../fonts/NotoSansKR-Medium.otf") format('opentype'),
        url("../fonts/NotoSansKR-Medium.otf") format('woff'),
        url("../fonts/NotoSansKR-Medium.otf") format('woff2'),
        url("../fonts/NotoSansKR-Medium.otf") format('truetype');
  
    font-weight: 500;
    font-style: medium;
  }
@font-face {
    font-family: "NotoSans";

    src: url("../fonts/NotoSansKR-Bold.otf") format('opentype'),
        url("../fonts/NotoSansKR-Bold.otf") format('woff'),
        url("../fonts/NotoSansKR-Bold.otf") format('woff2'),
        url("../fonts/NotoSansKR-Bold.otf") format('truetype');
  
    font-weight: 700;
    font-style: bold;
  }
@font-face {
    font-family: "NotoSans";

    src: url("../fonts/NotoSansKR-Black.otf") format('opentype'),
        url("../fonts/NotoSansKR-Black.otf") format('woff'),
        url("../fonts/NotoSansKR-Black.otf") format('woff2'),
        url("../fonts/NotoSansKR-Black.otf") format('truetype');
  
    font-weight: 900;
    font-style: black;
  }
@font-face {
    font-family: "Jua";

    src: url("../fonts/Jua-Regular.ttf") format('opentype'),
        url("../fonts/Jua-Regular.ttf") format('woff'),
        url("../fonts/Jua-Regular.ttf") format('woff2'),
        url("../fonts/Jua-Regular.ttf") format('truetype');
  
    font-weight: 400;
    font-style: normal;
  }
@font-face {
    font-family: "LeeSeoyun";

    src: url("../fonts/LeeSeoyun.ttf") format('opentype'),
        url("../fonts/LeeSeoyun.ttf") format('woff'),
        url("../fonts/LeeSeoyun.ttf") format('woff2'),
        url("../fonts/LeeSeoyun.ttf") format('woff2');
  
    font-weight: 400;
    font-style: normal;
  }